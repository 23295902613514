import './App.sass';
import Homepage from './containers/Homepage';

function App() {
  return (
    <div className="App">
      <Homepage />
    </div>
  )
}

export default App;
